<template>
  <div id="profile-container">
    <div id="application-status">
      <h1>Student Info</h1>
      <p v-if="status">
        {{ status }}
        <strong :class="classStyle"
          >&nbsp;{{ decision }}&nbsp;
          <strong v-if="finalization" :class="finalizationStyle"
            >({{ finalization }})</strong
          ></strong
        >
      </p>
    </div>
    <div id="student-info">
      <p>
        Name: {{ info.SPRIDEN_FIRST_NAME }} {{ info.SPRIDEN_LAST_NAME }} ({{
          info.SPRIDEN_ID
        }})
      </p>
    </div>
    <div>
      <!-- normal message for students not deposited or  -->
      <p
        id="display-message"
        v-html="
          this.incomplete_tasks && this.info.DEPOSIT_DATE && this.info.SARADAP_APST_CODE === 'D'
            ? this.info.Messages?.FINALIZE_MSG?.MESSAGE
            : parsedMessage
        "
      ></p>
    </div>
    <div
      v-if="incomplete_tasks && this.info.DEPOSIT_DATE"
      style="text-align: left"
    >
      <div class="modal-container" v-if="toggledMsg" @click="closeModal">
        <div class="modal">
          <p id="display-message" v-html="parsedMessage"></p>
          <button id="enroll-btn" @click="toggledMsg = !toggledMsg">
            CLOSE
          </button>
        </div>
      </div>
      <a id="msg-link" @click="toggledMsg = !toggledMsg">
        CLICK TO VIEW ADMISSION DECISION
      </a>
    </div>
    <!-- <button id="enroll-btn" @click="goToChangeMajor" v-if="changeMajor">
      CHANGE MAJOR
    </button> -->
    <div
      style="width: 100%; display: flex; justify-content: center"
      v-if="
        this.info.SARADAP_APST_CODE === 'D' &&
        (this.info.SARAPPD_APDC_CODE == 'AZ' ||
          this.info.SARAPPD_APDC_CODE == 'TZ') &&
        !this.info.DEPOSIT_DATE
      "
    >
      <EnrollButton :studentType="info.SARADAP_STYP_CODE" />
    </div>
  </div>
</template>

<script>
import EnrollButton from "../subcomponents/enrollButton";
import moment from "moment";
export default {
  components: { EnrollButton },
  name: "Profile",
  props: ["info", "appAttrs", "incomplete_tasks"],
  created() {
    console.log(this.info);
    this.getApplicationStatus();
    this.parsedMessage = this.parseMessageString(
      this.info.Messages?.STATUS_MSG?.MESSAGE
    );
  },
  data: function () {
    return {
      status: "",
      decision: "",
      classStyle: "",
      finalization: "",
      finalizationStyle: "",
      // changeMajor: null,
      parsedMessage: "",
      comDate: null,
      nuDate: null,
      toggledMsg: false,
    };
  },
  methods: {
    // goToChangeMajor: function () {
    //   window.open(
    //     "https://www.fau.edu/admissions/comrequest/index.php",
    //     "_blank"
    //   );
    // },
    formatDate: function (date) {
      let day = date.getDate();
    },
    parseMessageString: function (str) {
      const mappings = {
        "{TERM}": this.info.ENTRY_TERM_1,
        "{COM_DATE}": this.comDate,
        "{NURSING_DATE}": this.nuDate,
        "{FIRST_NAME}": this.info.SPRIDEN_FIRST_NAME,
        "{ZNUM}": this.info.SPRIDEN_ID,
      };
      const replaceFunc = (match) => {
        if (match in mappings) return mappings[match];
        else return match;
      };
      return str.replaceAll(/{[A-Za-z0-9_]+}/g, replaceFunc);
    },
    getApplicationStatus: function () {
      let apstCode = this.info.SARADAP_APST_CODE;
      let decCode = this.info.SARAPPD_APDC_CODE;
      let term = this.info.PROGRAM_CATALOG_TERM_1;
      if (apstCode == "C" || apstCode == "I" || apstCode == "W") {
        this.status = "Application Status:";
        this.classStyle = "incomplete-app";
        if (apstCode == "C") {
          let decDate = new Date(Date.parse(this.info.SARAPPD_APDC_DATE));
          // let nowDate = new Date();
          this.nuDate = moment(decDate).add(14, "d").format("MM/DD/YYYY");
          // const diffTime = Math.abs(nowDate - decDate);
          // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          // const deadline = new Date(decDate.valueOf());
          // deadline.setDate(decDate.getDate() + 30);
          // this.comDate = moment(deadline).format("MM/DD/YYYY");
          if (decCode == "WL") {
            this.decision = term + " - Waitlist";
          } else {
            this.decision = term + " - Complete (Pending Review)";
          }
          // if (
          //   (this.info.SARADAP_ATTRIBUTES?.includes("COMA") &&
          //     this.info.SARADAP_MAJR_CODE_1 === "PARC") ||
          //   (this.info.SARADAP_ATTRIBUTES?.includes("COMS") &&
          //     this.info.SARADAP_COLL_CODE_1 === "SC") ||
          //   (this.info.SARADAP_ATTRIBUTES?.includes("COME") &&
          //     this.info.SARADAP_COLL_CODE_1 === "EG") ||
          //   (this.info.SARADAP_ATTRIBUTES?.includes("NONU") &&
          //     this.info.SARADAP_COLL_CODE_1 === "NU")
          // ) {
          //   this.decision = term + " - Complete (Action Needed)";
          //   if (diffDays <= 30) {
          //     this.changeMajor = true;
          //   }
          // } else {
          //   this.decision = term + " - Complete (Pending Review)";
          // }
        } else if (apstCode == "I") {
          this.decision = term + " - Incomplete";
        } else if (apstCode == "W") {
          this.decision = term + " - Withdrawn";
        }
      } else if (apstCode == "D") {
        this.classStyle = "complete-app";
        if (decCode == "TZ" || decCode == "AZ") {
          this.status = "Decision Status:";
          if (this.info.DEPOSIT_DATE && this.incomplete_tasks) {
            this.decision = term + " - Admitted";
            this.finalizationStyle = "incomplete-app";
            this.finalization = "Not Finalized";
          } else if (this.info.DEPOSIT_DATE && !this.incomplete_tasks) {
            this.decision = term + " - Admitted";
            this.finalizationStyle = "complete-app";
            this.finalization = "Finalized";
          } else {
            this.decision = term + " - Admitted";
          }
        } else if (decCode == "??") {
          this.status = "Decision Status:";
          this.decision = term + " - Decisioned";
        } else {
          this.status = "";
          this.decision = "";
        }
      }
    },
    closeModal: function ($e) {
      if ($e.target.className === "modal-container") {
        this.toggledMsg = !this.toggledMsg;
      }
    },
  },
};
</script>

<style lang="scss">
.profile {
  padding: 0px 0 40px 0;

  #application-status {
    text-align: left;
    p {
      font-size: 24px;
      font-weight: bold;
    }
    strong {
      font-size: 24px;
      &.complete-app {
        color: green;
      }
      &.incomplete-app {
        color: red;
      }
    }
  }
  #student-info {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    p {
      margin-top: 0px;
    }
  }
  #display-message {
    font-size: 17px;
    line-height: 1.6;
    text-align: left;
  }
}
.app-attrs {
  padding-left: 40px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 30px;
}

.modal-container {
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #0000006b;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  .modal {
    max-height: 600px;
    width: 50%;
    padding: 20px;
    background-color: white;
    box-shadow: 0px 0px 32px #0000007d;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

#msg-link {
  text-decoration: underline;
  text-align: left;
  width: 100%;
  &:hover {
    font-weight: bold;
    cursor: pointer;
  }
}
</style>