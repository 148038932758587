<template>
  <div :class="'task-' + level">
    <div>
      <h3>{{ task.checl_title }}</h3>
      <div>
        <p
          v-if="level == 'incomplete'"
          v-html="parseMessageString(task.checl_desc_incomplete)"
        ></p>
        <p
          v-if="level == 'complete'"
          v-html="parseMessageString(task.checl_desc_complete)"
        ></p>
      </div>
      <button
        v-if="level == 'complete' && task.checl_comp"
        v-html="task.checl_comp"
      ></button>
      <button
        v-if="level == 'incomplete' && task.checl_incomp"
        v-html="task.checl_incomp"
        @click="buttonFunctionality"
      ></button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Task",
  props: ["task", "level", "confNum", "znum", "studentData"],
  data: function () {
    return {
      taskData: {
        title: null,
        text: null,
        complete: null,
        incomplete: null,
      },
      TSTS: {
        verifEmailSending: false,
        verifEmailSent: false,
        errorMsg: null,
      },
    };
  },
  created: function () {},
  methods: {
    buttonFunctionality: function () {
      switch (this.task.checkl_code) {
        case "HSTP":
          const ssarLink =
            "https://ssar.selfreportedtranscript.com/services/LinkLoginBridge.aspx?pt_ceeb=281DB5183C943EE6&pt_key=3C420E8E4314466D3462C502EE8835C5326C789068765C78&pt_sid=" +
            this.confNum;
          window.open(ssarLink, "_blank");
          break;
        case "APFE":
          window.open(`${API_URL}/touchnet.php`, "_blank");
          break;
        default:
          break;
      }
    },
    parseMessageString: function (str) {
      const mappings = {
        "{TERM}": this.studentData.ENTRY_TERM_1,
        "{ZNUM}": this.znum,
      };
      const replaceFunc = (match) => {
        if (match in mappings) return mappings[match];
        else return match;
      };
      return str.replaceAll(/{[A-Za-z0-9_]+}/g, replaceFunc);
    },
  },
};
</script>

<style lang="scss">
.submt-form {
  display: none;
}
.task-complete {
  position: relative;
  margin-top: 30px;
  border: 1px solid rgba(0, 0, 0, 0.212);
  border-radius: 15px;
  padding: 0px 20px 1rem 20px;
  margin-right: 30px;
  height: 150px;
  display: flex;
  // align-items: center;
  flex-direction: column;
  max-width: 41%;
  min-width: 41%;
  justify-content: space-between;
  box-shadow: 0px 0px 8px #56555534;
  overflow: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    // display: none;
    border-radius: 40px;
    padding-right: 20px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 100px;
  }
  &::-webkit-scrollbar-track-piece:start {
    margin-top: 40px;
  }
  &::-webkit-scrollbar-track-piece:end {
    margin-bottom: 40px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border: 5px solid transparent;
    background-clip: content-box;
    background-color: #c1c1c1;
  }
  h3 {
    text-align: left;
    color: #003066;
    font-size: 18px;
  }
  p {
    font-size: 16px !important;
  }
  div {
    display: flex;
    flex-direction: column;
    // align-items: center;
    button {
      margin: 0 auto;
      width: 71%;
      color: white;
      background-color: #003066;
      font-size: 15px;
      text-transform: uppercase;
      font-weight: bold;
      padding: 10px 20px;
      border: transparent;
      box-shadow: 0px 0px 8px #56555534;
      border: 1px solid rgba(0, 0, 0, 0.212);
      border-radius: 40px;
      &:hover {
        cursor: pointer;
        background-color: #002753;
      }
      &:active {
        cursor: pointer;
        background-color: #003c81;
      }
    }
  }
  &:hover {
    transform: scale(1.05);
    transition: all 0.3s;
  }
}
.task-incomplete {
  position: relative;
  margin-top: 30px;
  border: 1px solid rgba(0, 0, 0, 0.212);
  border-radius: 15px;
  padding: 0px 20px 1rem 20px;
  margin-right: 30px;
  height: 350px;
  display: flex;
  // align-items: center;
  min-width: 41%;
  flex-direction: column;
  max-width: 41%;
  justify-content: space-between;
  box-shadow: 0px 0px 8px #56555534;
  overflow: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    // display: none;
    border-radius: 40px;
    padding-right: 20px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 100px;
  }
  &::-webkit-scrollbar-track-piece:start {
    margin-top: 40px;
  }
  &::-webkit-scrollbar-track-piece:end {
    margin-bottom: 40px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border: 5px solid transparent;
    background-clip: content-box;
    background-color: #c1c1c1;
  }
  h3 {
    text-align: left;
    color: #cc0000;
    font-size: 18px;
  }
  p {
    font-size: 16px !important;
  }
  div {
    display: flex;
    flex-direction: column;
    // align-items: center;
    button {
      margin: 0 auto;
      width: 71%;
      color: white;
      background-color: #cc0000;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: bold;
      padding: 10px 20px;
      border: transparent;
      box-shadow: 0px 0px 8px #56555534;
      border: 1px solid rgba(0, 0, 0, 0.212);
      border-radius: 40px;
      &:hover {
        cursor: pointer;
        background-color: #ad0000;
      }
      &:active {
        cursor: pointer;
        background-color: #e00303;
      }
    }
  }
  &:hover {
    transform: scale(1.05);
    transition: all 0.3s;
  }
  #TSTS_errorMsg {
    color: red;
    font-size: 20px;
    margin-bottom: 30px;
  }
  #TSTS_sendingMsg {
    font-size: 20px;
    margin-bottom: 30px;
  }
}
</style>