<template>
  <div id="contact">
    <h1>Contact</h1>
    <br />
    <p>
      If you need further assistance with your application, have any questions
      regarding your application, or have any general admission inquiry, please
      feel free to reach out to us at
      <a href="mailto: admissions@fau.edu">admissions@fau.edu</a>, give us a
      call at <strong>(561) 297-3040</strong>, or chat with a live
      representative by clicking the
      <span
        aria-hidden="true"
        class="ivy-fa ivy-fa-comments-o"
        id="ivy-logo"
      ></span>
      button below.
    </p>
    <br />
  </div>
</template>
<script>
export default {
  name: "Contact",
  data() {
    return {};
  },
};
</script>

<style lang="scss">
#ivy-logo {
  font-size: 24px;
  margin: 0px 3px;
  background-color: #003366;
  color: white;
  padding: 10px;
  border-radius: 90px;
  box-shadow: 0px 3px 6px 0px #00000052;
}
#contact-container {
  display: flex;
  justify-content: center;
  p {
    line-height: 2.2;
  }
  @media screen and (max-width: 991px) {
    padding-bottom: 106px;
  }
}
#contact {
  max-width: 800px;
}
</style>