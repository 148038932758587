<template>
  <button
    v-if="studentType === 'C'"
    id="enroll-btn"
    class="active"
    @click="goToMyFau"
  >
    ENROLL NOW ON MYFAU
  </button>
  <button v-else id="enroll-btn" class="active" @click="goToOwlDone">
    ENROLL NOW ON OWLDONE
  </button>
</template>

<script>
export default {
  name: "EnrollButton",
  props: ["studentType"],
  data: function () {
    return {};
  },
  methods: {
    goToMyFau: function () {
      window.open("https://myfau.fau.edu/", "_blank");
    },
    goToOwlDone: function () {
      window.open("https://owldone.fau.edu/", "_blank");
    },
  },
};
</script>

<style lang="scss">
#enroll-btn {
  padding: 10px 20px;
  box-shadow: 0px 0px 8px #56555534;
  margin-bottom: 20px;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.212);
  font-size: 17px;
  font-weight: 600;
  border-radius: 40px;
  background-color: #003066;
  color: white;

  width: 30%;
  &.active {
    background-color: #003066;
    color: white;
    &:hover {
      cursor: pointer;
      color: white;
      background-color: #003066;
      transition: 0.3s all;
    }
  }
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
    color: white;
    background-color: rgb(195 2 4);
    transition: 0.3s all;
  }
}
</style>