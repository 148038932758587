<template>
  <div>
    <loading :active="isLoading" :can-cancel="true" :is-full-page="fullPage" />
    <ul>
      <li v-for="msg in data" :key="msg.Title" id="db-msg">
        <div
          @click="currentMsg == msg ? (currentMsg = null) : (currentMsg = msg)"
        >
          <h3>{{ msg.Title }}</h3>
          <p v-if="currentMsg == msg">{{ msg.Message }}</p>
          <p v-if="currentMsg != msg" v-html="msg.Message"></p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";

export default {
  name: "Admin",
  components: {
    Loading,
  },
  data: function () {
    return {
      isLoading: true,
      fullPage: true,
      data: null,
      currentMsg: null,
    };
  },
  beforeCreate: async function () {
    this.isLoading = true;

    await axios({
      method: "POST",
      url: `${API_URL}/getMsgs.php`,
      withCredentials: true,
      data: {
        env: process.env.NODE_ENV,
      },
    }).then((res) => {
      console.log(res);
      this.data = res.data;
      this.isLoading = false;
    });
  },
};
</script>

<style lang="scss">
#db-msg {
  list-style: none;
  padding: 10px;
  border: 1px solid #0000002b;
  border-radius: 3px;
  margin-bottom: 10px;
  &:hover {
    cursor: pointer;
  }
  p {
    text-align: left;
  }
}
</style>