<template>
  <span>
    <Loading :active="mainLoader" :is-full-page="true" />
    <Header />
    <Home @isLoading="isLoading" />
  </span>
</template>

<script>
import Header from "./components/Header";
import Home from "./components/Home.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "App",
  data: () => {
    return {
      mainLoader: true,
    };
  },
  components: {
    Home,
    Header,
    Loading,
  },
  methods: {
    isLoading: function (status) {
      this.mainLoader = false;
    },
  },
};
</script>

<style lang="scss">
html {
  scroll-behavior: smooth;
}
@font-face {
  font-family: Avenir Light;
  src: url("./assets/avenirltstd-light.otf");
}
@font-face {
  font-family: Avenir Book;
  src: url("./assets/Avenir-Book.ttf");
}
body {
  margin: unset;
  // overflow: hidden;
  // overflow-y: scroll;
}

button {
  font-family: Avenir Light, Avenir, Helvetica, Arial, sans-serif;
}
#app {
  font-family: Avenir Light, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
.right::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

.right::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}

.right::-webkit-scrollbar-thumb {
  background-color: rgba(170, 170, 170, 0.493); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  // border: 3px solid orange;  /* creates padding around scroll thumb */
}
.right::-webkit-scrollbar-button:end {
  height: 20px;
  width: 30%;
}
.right::-webkit-scrollbar-button:start {
  height: 20px;
  width: 30%;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
