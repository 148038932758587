import { createApp } from "vue";
import App from "./App.vue";
import VueScrollTo from "vue-scrollto";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckCircle, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// Initialize Vue app
const app = createApp(App);
// Add Vue directive
library.add(faCheckCircle)
library.add(faTimes)
app.component('font-awesome-icon', FontAwesomeIcon)
app.directive("scroll-to", VueScrollTo);
app.mount("#app");
