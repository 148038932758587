<template>
  <span>
    <div class="dashboard" ref="right">
      <div class="left" id="left">
        <img id="logo" alt="FAU logo" src="../assets/new-logo-with-wording.png" />
        <h2 id="app-status">APPLICATION STATUS PORTAL</h2>
        <transition name="fade" mode="out-in">
          <div
            v-if="loggedIn"
            :class="this.mobile ? 'btn-container' : 'btn-container2'"
            ref="btns"
          >
            <button
              :class="tab == 'profile' ? 'active' : ''"
              @click="scrollToElement('profile')"
            >
              STATUS
            </button>
            <button
              v-if="
                this.incomplete_tasks &&
                ((this.json.SARADAP_APST_CODE != 'D' &&
                  this.json.SARADAP_APST_CODE != 'C') ||
                  (this.json.SARADAP_APST_CODE === 'D' &&
                    this.json.DEPOSIT_DATE))
              "
              :class="tab == 'incomplete' ? 'active' : ''"
              @click="scrollToElement('incomplete')"
            >
              INCOMPLETE
            </button>
            <button
              v-if="
                this.complete_tasks &&
                this.json.SARADAP_APST_CODE != 'D' &&
                this.json.SARADAP_APST_CODE != 'C'
              "
              :class="tab == 'complete' ? 'active' : ''"
              @click="scrollToElement('complete')"
            >
              COMPLETE
            </button>
            <button v-if="location !== 'proxy'" @click="logout">LOG OUT</button>
            <button v-if="location === 'proxy'" @click="logout">GO BACK</button>
          </div>

          <div v-else-if="!loginLoading" class="btn-container" ref="btns">
            <button
              :class="tab == 'login' ? 'active' : ''"
              @click="tab = 'login'"
            >
              LOG IN
            </button>
            <button
              :class="tab == 'contact' ? 'active' : ''"
              @click="tab = 'contact'"
            >
              CONTACT
            </button>
          </div>
        </transition>
      </div>
      <div id="mobile-left" v-if="mobile" @click="toggleMobileMenu"></div>
      <div class="right">
        <transition-group>
          <transition name="fade" mode="out-in">
            <div v-if="loggedIn && this.json" id="right-container">
              <Profile
                :info="this.json"
                :appAttrs="appAttrs"
                :incomplete_tasks="this.incomplete_tasks?.length ? true : false"
                class="profile"
                ref="profile"
              />
              <Incomplete
                v-if="
                  this.incomplete_tasks &&
                  ((this.json.SARADAP_APST_CODE != 'D' &&
                    this.json.SARADAP_APST_CODE != 'C') ||
                    (this.json.SARADAP_APST_CODE === 'D' &&
                      this.json.DEPOSIT_DATE))
                "
                :tasks="this.incomplete_tasks"
                :confNum="this.json.CONF_NO"
                :znum="this.json.SPRIDEN_ID"
                :studentData="this.json"
                :incMsg="this.json.Messages.INC_MSG.MESSAGE"
                class="incomplete"
                ref="incomplete"
              />
              <Complete
                v-if="
                  this.complete_tasks &&
                  this.json.SARADAP_APST_CODE != 'D' &&
                  this.json.SARADAP_APST_CODE != 'C'
                "
                :confNum="this.json.CONF_NO"
                :znum="this.json.SPRIDEN_ID"
                :studentData="this.json"
                :tasks="this.complete_tasks"
                class="complete"
                ref="complete"
              />
              <div id="test-score-update" v-if="['B','E'].includes(this.json?.SARADAP_STYP_CODE)">
                <h1>TEST SCORE UPDATE</h1>
                <TestScoreUpdateButton
                  :email="this.json?.original?.email?.trim()"
                  :studentData="this.json"
                >
                  If you have updated test scores that you would like to submit
                  to FAU, you may add or update scores at any time in the
                  admission process by following these steps:
                  <ul>
                    <li>Click the button below.</li>
                    <li>
                      We will send a verification code to
                      <b>{{
                        this.json?.original?.email?.trim()
                          ? this.json?.original?.email?.trim()
                          : "your personal email address"
                      }}</b>
                      to verify your identity.
                    </li>
                    <li>
                      You will be prompted to enter the verification code.
                    </li>
                    <li>
                      You will then be redirected to a form to update your
                      scores.
                    </li>
                  </ul>
                  <br /><br />
                </TestScoreUpdateButton>
              </div>

              <div class="app-info">
                <h1>APPLICATION INFORMATION</h1>
                <div class="app-attrs">
                  <ApplicationAttr
                    v-for="(attr, i) in appAttrs"
                    :key="i"
                    :title="attr.title"
                    :value="attr.value"
                    :index="i"
                  />
                </div>
              </div>
            </div>
            <div v-else-if="!loggedIn" ref="loggedOut">
              <transition name="fade" mode="out-in">
                <div v-if="tab == 'login' || location == 'proxy'">
                  <Login
                    @login="login"
                    v-if="location != 'proxy' && location != 'admin'"
                    ref="proxyLogin"
                    @goToContact="tab = 'contact'"
                  />
                  <Proxy v-if="location == 'proxy'" @login="login" />
                  <Admin v-if="location == 'admin'" />
                </div>
                <div v-else-if="tab == 'contact'" id="contact-container">
                  <Contact />
                </div>
              </transition>
            </div>
          </transition>
        </transition-group>
      </div>
    </div>
    <Footer />
  </span>
</template>

<script>
import Incomplete from "./incomplete.vue";
import Login from "./login.vue";
import Profile from "./profile.vue";
import Complete from "./complete.vue";
import EnrollButton from "../subcomponents/enrollButton";
import Contact from "./Contact";
import axios from "axios";
import Footer from "./footer";
import ApplicationAttr from "../subcomponents/applicationAttr";
import Proxy from "./Proxy";
import Admin from "./Admin";
import TestScoreUpdateButton from "../subcomponents/TestScoreUpdateButton.vue";
export default {
  name: "Home",
  components: {
    Login,
    Profile,
    Incomplete,
    Complete,
    Footer,
    Footer,
    EnrollButton,
    Contact,
    ApplicationAttr,
    Proxy,
    Admin,
    TestScoreUpdateButton,
  },
  props: {},
  data: function () {
    return {
      tab: "login",
      json: null,
      input: {
        username: "",
        password: "",
      },
      loggedIn: false,
      appAttrs: null,
      complete_tasks: null,
      incomplete_tasks: null,
      mobile: false,
      loginLoading: true,
      znum: "",
    };
  },
  beforeCreate: async function () {
    if (window.location.pathname.includes("admin")) {
      this.location = "admin";
      this.$emit("isLoading", false);
    } else if (window.location.pathname.includes("proxy")) {
      this.location = "proxy";
      this.$emit("isLoading", false);
    }
  },
  created: async function () {
    this.isMobile();
  },
  mounted() {},
  unmounted() {},

  methods: {
    toggleMobileMenu() {
      let left = document.getElementById("left");
      let mobileLeft = document.getElementById("mobile-left");
      mobileLeft.style.display = "none";
      left.className = "left";
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    },
    login: function (status, studentData = null, proxyLogin = null) {
      this.loginLoading = false;
      this.$emit("isLoading", false);
      this.loggedIn = status;
      this.tab = status ? "profile" : "login";
      if (studentData?.student) {
        // Redirect to new Slate appstatus portal for apps Spring 2025 and later 
        if (studentData?.student?.SARADAP_TERM_CODE_ENTRY >= "202501") {
          this.loggedIn = false;
          this.tab = "login";
          this.$emit("login", false);
          document.cookie = `BIGipServerPROD_appstatus2_https=;Expires=Thu, 01 Jan 1970 00:00:00 GMT`;
          document.cookie = `PHPSESSID=;Expires=Thu, 01 Jan 1970 00:00:00 GMT`;
          window.location.href = 'https://admissions.fau.edu/apply/status';
        }
        this.json = studentData.student;
        this.getApplicationType();
        this.addAppAttrs();
        this.sortTasks();
        window.onscroll = this.onScroll;
      }
    },
    logout: () => {
      if (window.location.pathname.includes("proxy")) {
        window.location.reload();
      } else {
        axios({
          method: "POST",
          url: `${API_URL}/logOut.php`,
          withCredentials: true,
          data: {
            env: process.env.NODE_ENV,
          },
        }).then((res) => {
          if (res.data) {
            window.location.reload(true);
          }
        });
      }
    },
    addAppAttrs: function () {
      this.appAttrs = [
        { title: "Entry Term", value: this.json.ENTRY_TERM_1 },
        { title: "Major", value: this.json.STVMAJR_DESC },
        { title: "Z Number", value: this.json.SPRIDEN_ID },
        { title: "Application Type", value: this.application_type },
        { title: "College", value: this.json.STVCOLL_DESC },
        { title: "Confirmation Number", value: this.json.CONF_NO },
        { title: "Level", value: "Undergraduate" },
        {
          title: "Residency",
          value: this.json.incomplete_checklist_items_str?.includes("RSVF")
            ? "Unverified " + this.json.STVRESD_DESC
            : this.json.STVRESD_DESC,
        },
        {
          title: "Date Applied",
          value: this.json.SARADAP_APPL_DATE.split("T")[0],
        },
      ];
    },
    onScroll(e) {
      this.offsetTop = window.pageYOffset || document.documentElement.scrollTop;
      let profile = "";
      let complete = "";
      let incomplete = "";

      if (this.$refs.profile) {
        profile = this.$refs.profile.$el;
      }
      if (this.$refs.complete) {
        complete = this.$refs.complete.$el;
      }
      if (this.$refs.incomplete) {
        incomplete = this.$refs.incomplete.$el;
      }
      if (this.isElementInViewport(profile) && profile) {
        this.tab = "profile";
      } else if (this.isElementInViewport(complete) && complete) {
        this.tab = "complete";
      } else if (this.isElementInViewport(incomplete) && incomplete) {
        this.tab = "incomplete";
      } else {
        return;
      }
    },
    isElementInViewport(el) {
      var top = el.offsetTop;
      var left = el.offsetLeft;
      var width = el.offsetWidth;
      var height = el.offsetHeight;

      while (el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
        left += el.offsetLeft;
      }

      return (
        top >= window.pageYOffset &&
        left >= window.pageXOffset &&
        (top + height <= window.pageYOffset + window.innerHeight ||
          (top <= window.pageYOffset &&
            top + height >= window.pageYOffset + window.innerHeight)) &&
        left + width <= window.pageXOffset + window.innerWidth
      );
    },
    scrollToElement: function (elName) {
      const el = document.getElementsByClassName(elName)[0];
      let right = this.$refs.right;
      right.removeEventListener("scroll", this.onScroll);
      if (el) {
        el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
        this.tab = elName;
      }
      setTimeout(() => {
        right.addEventListener("scroll", this.onScroll);
      }, 100);
    },
    getApplicationType() {
      switch (this.json.SARADAP_STYP_CODE) {
        case "C":
          this.application_type = "Returning Former";
          break;
        case "B":
        case "E":
          this.application_type = "Freshman";
          break;
        case "J":
        case "U":
          this.application_type = "Transfer";
          break;
        case "S":
          this.application_type = "Second Baccalaureate";
          break;
      }
    },
    sortTasks() {
      let completeTasks = [];
      let incompleteTasks = [];

      for (let completeTask of this.json.complete_checklist_items) {
        this.json.ChecklistItemsUIData.filter((taskData) => {
          if (
            completeTask.SARCHKL_ADMR_CODE == taskData.checkl_code &&
            // completeTask.SARCHKL_ADMR_CODE != "TSTS" &&
            completeTask.SARCHKL_ADMR_CODE != "CNDT"
          ) {
            completeTasks.push(taskData);
          } else if (
            completeTask.SARCHKL_ADMR_CODE?.includes("CT") &&
            taskData.checkl_code == "CT"
          ) {
            let schoolName = completeTask.SARCHKL_COMMENT?.replace(/\s+/g,' ').trim().split(" ") // Removes double white spaces and trims the string before splitting
              .map((word) => {
                if (!word) return '';
                if (word !== "OF") {
                  return word[0].toUpperCase() + word.substr(1).toLowerCase();
                } else {
                  return "of";
                }
              })
              .join(" ");
            let formatTask = {
              ...taskData,
              checl_title: `College Transcript${
                schoolName ? " - " + schoolName : ""
              }`,
            };
            if (
              completeTask.SARCHKL_CKST_CODE === "PT" &&
              this.json.DEPOSIT_DATE
            ) {
              incompleteTasks.push(formatTask);
            } else {
              completeTasks.push(formatTask);
            }
          }
        });
      }
      completeTasks.length > 0 ? (this.complete_tasks = completeTasks) : null;
      for (let incompleteTask of this.json.incomplete_checklist_items) {
        this.json.ChecklistItemsUIData.filter((taskData) => {
          if (
            incompleteTask.SARCHKL_ADMR_CODE == taskData.checkl_code &&
            incompleteTask.SARCHKL_ADMR_CODE != "TSVR" &&
            incompleteTask.SARCHKL_ADMR_CODE != "TYRE"
          ) {
            incompleteTasks.push(taskData);
          } else if (
            incompleteTask.SARCHKL_ADMR_CODE?.includes("CT") &&
            taskData.checkl_code == "CT"
          ) {
            if (!incompleteTask.SARCHKL_COMMENT) {
              let formatTask;
              if (
                this.json.SARADAP_STYP_CODE === "B" ||
                this.json.SARADAP_STYP_CODE === "E"
              ) {
                formatTask = {
                  ...taskData,
                  checl_title: `College Transcript - Dual Enrollment / Exam Credit`,
                };
              } else {
                formatTask = {
                  ...taskData,
                  checl_title: `Missing College Credits`,
                };
              }
              incompleteTasks.push(formatTask);
            } else {
              let schoolName = incompleteTask.SARCHKL_COMMENT?.replace(/\s+/g,' ').trim().split(' ') // Removes double white spaces and trims the string before splitting
                .map((word) => {
                  if (!word) return '';
                  if (word !== "OF") {
                    return word[0].toUpperCase() + word.substr(1).toLowerCase();
                  } else {
                    return "of";
                  }
                })
                .join(" ");
              let formatTask = {
                ...taskData,
                checl_title: `College Transcript${
                  schoolName ? " - " + schoolName : ""
                }`,
              };
              incompleteTasks.push(formatTask);
            }
          } else if (
            incompleteTask.SARCHKL_ADMR_CODE == taskData.checkl_code &&
            this.json.DEPOSIT_DATE &&
            incompleteTask.SARCHKL_ADMR_CODE === "TSVR"
          ) {
            incompleteTasks.push(taskData);
          }
        });
      }
      incompleteTasks.length > 0
        ? (this.incomplete_tasks = incompleteTasks)
        : null;
    },
  },
};
</script>
<style lang="scss" >
@import url("../assets/styles/mobile.scss");
#mobile-left {
  display: none;
  width: 100%;
  position: fixed;
  height: 100vh;
  top: 0;
  z-index: 1;
}
h1 {
  font-size: 40px;
  text-transform: uppercase;
}

.dashboard {
  display: flex;
  .left {
    min-height: 620px;
    @media screen and(max-width:1300px) {
      min-height: 220px;
    }
    padding: 30px 20px;
    width: 100%;
    margin-right: 40px;
    max-width: 200px;
    display: flex;
    flex-direction: column;
    position: relative;
    background: #ccc;
    .btn-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      button {
        padding: 10px 20px;
        box-shadow: 0px 0px 8px #999;
        margin-bottom: 20px;
        background-color: white;
        border: 1px solid rgba(0, 0, 0, 0.212);
        font-size: 17px;
        font-weight: 600;
        border-radius: 40px;
        width: 100%;

        &.active {
          background-color: #003066;
          color: white;
          &:hover {
            cursor: pointer;
            color: white;
            background-color: #003066;
            transition: 0.3s all;
          }
        }
        &:focus {
          outline: none;
        }
        &:hover {
          cursor: pointer;
          color: white;
          background-color: rgb(195 2 4);
          transition: 0.3s all;
        }
      }
    }
  }
  .btn-container2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    width: inherit;
    max-width: inherit;
    top: 40vh;
    button {
      padding: 10px 20px;
      box-shadow: 0px 0px 8px #999;
      margin-bottom: 20px;
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.212);
      font-size: 17px;
      font-weight: 600;
      border-radius: 40px;
      width: 100%;

      &.active {
        background-color: #003066;
        color: white;
        &:hover {
          cursor: pointer;
          color: white;
          background-color: #003066;
          transition: 0.3s all;
        }
      }
      &:focus {
        outline: none;
      }
      &:hover {
        cursor: pointer;
        color: white;
        background-color: rgb(195 2 4);
        transition: 0.3s all;
      }
    }
  }
  .right {
    width: 70%;
    position: relative;
    padding: 40px 0;
  }
}
#app-status {
  margin-bottom: 50px;
  margin-top: 50px;
}
.ivy-main.ivy-pos-bottom-right.ivy-t-circle .ivy-circle {
  background-color: white !important;
  color: #003366 !important;
}
.app-info {
  h1 {
    text-align: left;
  }
  padding: 20px 0;
}
#test-score-update {
  text-align: left;
  padding: 20px 0;
  h1 {
    text-align: left !important;
  }
}
</style>
