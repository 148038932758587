<template>
  <div class="footer-container">
    <div class="footer">
      <div class="footer-item">
        <img src="../assets/footer-logo.png" alt="" />
        <p>
          Florida Atlantic University
          <br />
          777 Glades Road
          <br />
          Boca Raton, FL 33431
        </p>
      </div>
      <div class="footer-item">
        <p>CAMPUSES</p>
        <ul>
          <li>
            <a href="https://www.fau.edu/about/boca-raton.php">BOCA RATON</a>
          </li>
          <li>
            <a href="https://www.fau.edu/broward/about/dania-beach/">
              DANIA BEACH
            </a>
          </li>
          <li><a href="https://www.fau.edu/broward/about/davie/">DAVIE</a></li>
          <li>
            <a href="https://www.fau.edu/broward/about/fort-lauderdale/">
              FORT LAUDERDALE
            </a>
          </li>
          <li><a href="https://www.fau.edu/hboi/">HARBOR BRANCH</a></li>
          <li><a href="https://www.fau.edu/jupiter/">JUPITER</a></li>
        </ul>
      </div>
      <div class="footer-item">
        <p>RESOURCES</p>
        <ul>
          <li>
            <a href="https://www.fau.edu/registrar/registration/calendar.php">
              Academic Calendar
            </a>
          </li>
          <li>
            <a href="https://www.fau.edu/jobs/">Employment Opportunities</a>
          </li>
          <li><a href="https://www.fau.edu/programs/">Program Search</a></li>
          <li><a href="https://www.fau.edu/parking/">Parking</a></li>
          <li><a href="https://www.fau.edu/report/">Report a Concern</a></li>
          <li>
            <a href="https://www.fau.edu/registrar/transcripts.php">
              Transcripts
            </a>
          </li>
        </ul>
      </div>
      <div class="footer-item">
        <p>INFO</p>
        <ul>
          <li><a href="https://www.fau.edu/about/contact/">Contact Us</a></li>
          <li><a href="https://directory.fau.edu/">Directory</a></li>
          <li>
            <a href="https://www.fau.edu/emergency/">Emergency &amp; Safety</a>
          </li>
          <li>
            <a href="https://www.fau.edu/education/school-and-k12-programs/">
              FAU Schools
            </a>
          </li>
          <li>
            <a
              href="https://www.fau.edu/publicaffairs/media-relations/guidelines.php"
            >
              Media
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss">
.footer-container {
  padding: 40px 110px;
  // margin-top: 70px;
  background-color: #036;
}
.footer {
  max-width: 90%;
  font-family: Avenir Book;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .footer-item {
    display: flex;
    flex-direction: column;

    img {
      width: 120px;
    }
    p {
      color: white;
      text-align: left;
    }
    ul {
      list-style: none;
      padding: unset;
      text-align: left;
      li {
        color: white;
        font-weight: bold;
        a {
          color: white;
          text-decoration: none;
        }
      }
    }
  }
}
</style>