<template>
  <div id="backdrop">
    <p>
      To verify your account we sent you a verification at
      <strong>{{ email }}</strong
      >, enter the verification code below to access the Test Score Form and
      update your test scores.
    </p>
    <br />
    <div>
      <input
        type="text"
        v-model="verificationCode"
        placeholder="Enter verification code"
        @input="autoVerify"
      />
      <div
        style="position: relative; display: inline-block; vertical-align: top"
      >
        <font-awesome-icon
          v-if="success === true"
          icon="check-circle"
          color="green"
          size="2x"
          style="position: absolute; left: 10; top: 10"
        />
        <font-awesome-icon
          v-if="success === false"
          style="position: absolute; left: 10; top: 10"
          icon="times"
          color="red"
          size="2x"
        />
      </div>
    </div>
    <br />
    <div style="color: red; margin: 20px auto">{{ errorMsg }}</div>
    <br />
    <button @click="verifyCode" id="verifyBtn">Verify</button>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "EmailVerificationCodePopup",
  props: ["email", "studentData"],
  data: function () {
    return {
      verificationCode: null,
      success: null, // bool
      errorMsg: "",
    };
  },
  methods: {
    verifyCode: function () {
      axios({
        method: "POST",
        url: `${API_URL}/emailToVerifyUser.php`,
        withCredentials: true,
        data: {
          email: this.email,
          code: this.verificationCode.trim(),
          pidm: this.studentData?.SPRIDEN_PIDM,
          conf: this.studentData?.CONF_NO,
          env: process.env.NODE_ENV,
        },
      })
        .then((res) => {
          this.success = res.data?.success;
          if (this.success) {
            const frevvoFormLink = res.data?.frevvoFormLink;
            const hash = res.data?.hash;
            const timestamp = res.data?.timestamp;
            const znum = this.studentData?.SPRIDEN_ID;
            const pidm = this.studentData?.SPRIDEN_PIDM;
            const conf = this.studentData?.CONF_NO;
            const email = this.email;
            const urlParams = `?_data=(h:"${hash}",ts:"${timestamp}",ZNUMBER:"${znum}",pidm:"${pidm}",conf:"${conf}",email:"${email}",env:"${process.env.NODE_ENV}")`;
            setTimeout(() => {
              window.open(frevvoFormLink + urlParams, "_blank");
            }, 400);
          } else {
            console.log(res);
            this.errorMsg =
              "Invalid verification code. Try refreshing the page and claiming a new one.";
          }
        })
        .catch((err) => {
          NProgress.done(); //Remove the loading bar
          console.log(err);
          this.success = false;
          this.errorMsg = "Server error.";
        });
    },
    autoVerify: function () {
      this.success = null;
      this.errorMsg = null;
      if (this.verificationCode.trim().length == 12) {
        this.verifyCode();
      }
    },
  },
};
</script>

<style lang="scss">

#backdrop {
  padding: 10px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    text-align: center;
    font-size: 18px;
  }
  input {
    padding: 12px 5px;
    font-size: 18px;
    text-align: center !important;
    @media screen and(max-width:991px) {
      font-size: 13px;
    }
    background-color: rgba(105, 105, 105, 0.089);
    &:focus {
      background-color: white;
      outline: none;
    }
    &:active {
      background-color: white;
    }
    &.error {
      background-color: rgba(255, 0, 0, 0.301) !important;
    }
  }
  button {
    margin: 0 auto;
    width: 40%;
    color: white;
    display: block;
    background-color: #cc0000;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    padding: 12px 5px;
    border: transparent;
    box-shadow: 0px 0px 8px #56555534;
    border: 1px solid rgba(0, 0, 0, 0.212);
    border-radius: 40px;
    &:hover {
      cursor: pointer;
      background-color: #ad0000;
    }
    &:active {
      cursor: pointer;
      background-color: #e00303;
    }
  }
}
</style>