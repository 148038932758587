<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="!isDisplayingLoginScreen && !isLoading">
        <h1 style="text-transform: none;">Welcome to your Application Status Portal</h1>
        <p style="font-size: 20px;">To proceed with logging in, please select your enrollment term:</p>
        <div class="selectAppStatusVersionContainer">
          <div class="selectAppStatusVersionBtn" @click="displayLoginScreen"><p>Summer 2024 <br>&<br> Fall 2024</p></div>
          <br>
          <div class="selectAppStatusVersionBtn" @click="goToSlateAppStatus"><p>Spring 2025</p></div>
        </div>
      </div>
      <div v-else-if="isDisplayingLoginScreen && !forgotConfirmation.active && !isLoading" class="login-form">
        <div>
          <h1>Log In</h1>
          <p id="login-prompt" style="font-weight: 200 !important">
            Log in below to view the status of your Summer 2024 or Fall 2024 Florida Atlantic University
            application.
          </p>
          <input
            v-model="input.username"
            type="text"
            placeholder="Personal Email Address"
            :style="input.username ? { 'background-color': 'white' } : ''"
            :class="usernameError ? 'error' : ''"
            id="login-inp"
          />
          <span class="errorMsg">{{ usernameError }}</span>
        </div>
        <div>
          <input
            v-model="input.password"
            type="password"
            placeholder="Confirmation #"
            :style="input.password ? { 'background-color': 'white' } : ''"
            :class="passwordError ? 'error' : ''"
            id="login-inp"
          />
          <span class="forgot-conf" @click="toggleForget">
            Forgot Confirmation #?
          </span>
          <span class="errorMsg">{{ passwordError }}</span>
        </div>
        <div>
          <button @click="login" :disabled="this.loading">
            {{ !this.loading ? "LOG IN" : "LOGGING IN..." }}
          </button>
          <div class="errorMsg">{{ loginError }}</div>
        <div style="border: none; background-color: #d9ecff;  padding: 8px;">
          <b>Spring 2025 applicants</b> Check your application status using the <b>
            <a href="https://admissions.fau.edu/apply/status" target="_blank" style="color:#126bd9;">Admissions
              Application Portal</a>
          </b>
        </div>
        </div>
        <div class="login-helper">
          <p @click="goToContact">Need Help?</p>
        </div>
      </div>
      <div v-else-if="isDisplayingLoginScreen && forgotConfirmation.active" id="forgot-container">
        <h1>Retrieve Confirmation</h1>
        <p id="login-prompt" style="font-weight: 200 !important">
          if your email is not working, please note that applications take 48
          hours to load. <br />
          if the problem persists, please contact the office at 561-297-3040 to
          restore your email address.
        </p>
        <input
          v-model="forgotConfirmation.email"
          type="email"
          id="forgot-email"
          placeholder="Personal Email Address"
        />
        <div id="btn-container">
          <button id="submit-btn" @click="recoverConfirmationNumber">
            SUBMIT
          </button>
          <button
            id="cancel-btn"
            @click="
              forgotConfirmation.active = false;
              forgotConfirmation.email = '';
              forgotConfirmation.emailSent = false;
            "
          >
            GO BACK TO LOGIN
          </button>
        </div>
        <p
          v-if="isDisplayingLoginScreen && forgotConfirmation.emailSent"
          style="color: green; font-size: 17px"
        >
          The confirmation number has been sent to the email above!
        </p>
        <p v-if="isDisplayingLoginScreen && forgotConfirmation.error" style="color: red; font-size: 17px">
          {{ this.forgotConfirmation.error }}
        </p>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Login",
  emits: ["login"],
  components: {
    Loading,
  },
  created: async function () {
    //Look for PHP SESSION Cookie
    if (document.cookie.match(/PHPSESSID=[^;]+/)) {
      //Call login
      this.isLoading = true;
      await axios({
        method: "POST",
        url: `${API_URL}/getData.php`,
        withCredentials: true,
        data: {
          env: process.env.NODE_ENV,
        },
      }).then((res) => {
        this.loading = false;
        if (res.data?.student) {
          this.$emit("login", true, res.data);
        } else {
          this.loginError = res.data.errorMsg;
          this.$emit("login", false);
        }
        console.log(res);
      });
    } else {
      this.$emit("login", false);
    }
    this.isLoading = false;
  },
  data: function () {
    return {
      input: {
        username: "",
        password: "",
      },
      isDisplayingLoginScreen: false,
      isLoading: false,
      usernameError: "",
      passwordError: "",
      loginError: "",
      forgotConfirmation: {
        active: false,
        email: "",
        loading: false,
        error: "",
        emailSent: false,
      },
    };
  },
  methods: {
    displayLoginScreen: function () {
      this.isDisplayingLoginScreen = true;
    },
    goToSlateAppStatus: function () {
      window.location.assign("https://admissions.fau.edu/apply/status");
    },
    goToContact: function () {
      this.$emit("goToContact");
    },
    login: function () {
      this.loading = true;
      this.loginError = null;
      //Make sure field inputs are valid
      if (
        this.validateEmail(this.input.username?.trim()) &&
        this.validateConfNumber(this.input.password?.trim())
      ) {
        // before a request is made start the nprogress
        axios.interceptors.request.use((config) => {
          NProgress.start();
          NProgress.set(0.6);
          return config;
        });

        // before a response is returned stop nprogress
        axios.interceptors.response.use((response) => {
          NProgress.done();
          return response;
        });
        //Get student data from API
        axios({
          method: "POST",
          url: `${API_URL}/getData.php`,
          withCredentials: true,
          data: {
            email: this.input.username?.trim(),
            conf: this.input.password?.trim(),
            env: process.env.NODE_ENV,
          },
        })
          .then((res) => {
            this.loading = false;
            if (res.data?.student) {
              this.$emit("login", true, res.data);
            } else {
              this.loginError = res.data.errorMsg;
              this.$emit("login", false);
            }
            console.log(res);
          })
          .catch((err) => {
            this.loading = false;
            if (err.response) {
              // client received an error response (5xx, 4xx)
              this.loginError = JSON.stringify(err);
            } else if (err.request) {
              // client never received a response, or request never left
              this.loginError = JSON.stringify(err);
            } else {
              console.log(err);
            }
          });
      } else {
        this.loading = false;
      }
    },
    validateEmail: function (email) {
      let isValid = true;
      if (!email) {
        this.usernameError = "What is the email you applied to FAU with?";
        isValid = false;
      } else if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          email
        )
      ) {
        this.usernameError = "You have entered an invalid email address!";
        isValid = false;
      }
      if (isValid) this.usernameError = "";
      return isValid;
    },
    validateConfNumber: function (conf) {
      let isValid = true;
      if (!conf) {
        this.passwordError =
          "What is the confirmation number of your application?";
        isValid = false;
      } else if (!/^[0-9]+$/.test(conf)) {
        this.passwordError = "A confirmation number contains only numbers.";
        isValid = false;
      }
      if (isValid) this.passwordError = "";
      return isValid;
    },
    toggleForget: function () {
      this.forgotConfirmation.active = true;
    },
    recoverConfirmationNumber: function () {
      this.forgotConfirmation.emailSent = false;
      this.forgotConfirmation.loading = true;
      this.forgotConfirmation.error = null;
      //Make sure field inputs are valid
      if (this.validateEmail(this.forgotConfirmation.email?.trim())) {
        // before a request is made start the nprogress
        axios.interceptors.request.use((config) => {
          NProgress.start();
          NProgress.set(0.6);
          return config;
        });
        // before a response is returned stop nprogress
        axios.interceptors.response.use((response) => {
          NProgress.done();
          return response;
        });
        //Get student data from API
        axios({
          method: "POST",
          url: `${API_URL}/emailConfNumber.php`,
          data: {
            email: this.forgotConfirmation.email?.trim(),
            env: process.env.NODE_ENV,
          },
        })
          .then((res) => {
            this.forgotConfirmation.loading = false;
            if ("data" in res && this.isValidJSON(JSON.stringify(res.data))) {
              if (res.data?.Message == "Email sent!") {
                this.forgotConfirmation.emailSent = true;
              } else {
                this.forgotConfirmation.error = res.data?.Message;
                this.forgotConfirmation.emailSent = false;
              }
            } else {
              // data is not valid JSON, something must be wrong in the php file. Email might or might not have been sent.
              this.forgotConfirmation.error =
                "We have encountered an error in our system. However, you may still have received an email. Please verify your inbox and try again later if you have not received an email.";
              this.forgotConfirmation.emailSent = false;
            }
            console.log(res);
          })
          .catch((err) => {
            NProgress.done(); //Remove the loading bar
            this.forgotConfirmation.loading = false;
            this.forgotConfirmation.emailSent = false;
            this.forgotConfirmation.error =
              "We have encountered an error in our system. We are working on fixing the issue. Please try again later.";

            console.log(err);
            // if (err.response) {
            //   // client received an error response (5xx, 4xx)
            //   this.forgotConfirmation.error = JSON.stringify(err);
            // } else if (err.request) {
            //   // client never received a response, or request never left
            //   this.forgotConfirmation.error = JSON.stringify(err);
            // } else {
            //   // Don't know
            // }
          });
      } else {
        this.forgotConfirmation.loading = false;
        this.forgotConfirmation.error =
          "You have entered an invalid email address!";
      }
    },
    isValidJSON: function (item) {
      item = typeof item !== "string" ? JSON.stringify(item) : item;
      try {
        item = JSON.parse(item);
      } catch (e) {
        return false;
      }
      if (typeof item === "object" && item !== null) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
.selectAppStatusVersionContainer {
  margin-top: 5em;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
}
.selectAppStatusVersionBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8em;
  width: 8em;
  padding: 20px;
  border: 10px solid rgb(195 2 4);
  background-color: #036;
  font-size: 25px;
  font-weight: bold;
  color: white;
  margin-left: 100px;
  margin-right: 100px;
}
.login-form {
  display: flex;
  flex-direction: column;
  width: 40% !important;
  align-items: center;
  margin: 0 auto;

  button {
    padding: 12px 10px;
    width: 45% !important;
    font-size: 17px;

    // font-weight: bold;
    @media screen and (max-width: 991px) {
      font-size: 13px;
    }

    border-radius: 40px;
    text-transform: uppercase;
    background-color: #036;
    color: white;

    &.active {
      background-color: #003066;
      color: white;

      &:hover {
        cursor: pointer;
        color: white;
        background-color: #003066;
        transition: 0.3s all;
      }
    }

    &:focus {
      outline: none;
    }

    &:hover {
      cursor: pointer;
      color: white;
      background-color: rgb(195 2 4);
      transition: 0.3s all;
    }
  }

  div {
    width: 100%;
    margin-bottom: 30px;

    p {
      font-size: 18px;
      font-weight: bold;
      display: flex;
    }

    input {
      width: 100%;
      padding: 12px 5px;
      font-size: 18px;

      @media screen and(max-width:991px) {
        font-size: 13px;
      }

      background-color: rgba(105, 105, 105, 0.089);

      &:focus {
        background-color: white;
        outline: none;
      }

      &:active {
        background-color: white;
      }

      &.error {
        background-color: rgba(255, 0, 0, 0.301) !important;
      }
    }

    .errorMsg {
      color: red;
      font-size: 13px;
      text-align: left;
    }
  }
}

#login-prompt {
  font-size: 18px;
  padding: 5%;
}

.forgot-conf {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  margin-top: 8px;
  margin-left: 1px;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
}

.login-helper {
  margin-top: 30px;
  display: flex;
  justify-content: right;

  @media screen and (max-width: 991px) {
    margin-top: unset !important;

    p {
      font-size: 13px !important;
      margin: unset !important;
    }
  }

  p {
    &:hover {
      cursor: pointer;
    }
  }
}

#nprogress .bar {
  background: #036 !important;
}

#nprogress .spinner-icon {
  border-top-color: rgb(0, 99, 197);
  border-left-color: #036;
}

#forgot-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  p {
    padding: 10px 0;
  }

  #forgot-email {
    width: 40%;
    padding: 12px 5px;
    font-size: 18px;

    @media screen and(max-width:991px) {
      font-size: 13px;
    }

    margin: 20px 0;
    background-color: rgba(105, 105, 105, 0.089);

    &:focus {
      background-color: white;
      outline: none;
    }

    &:active {
      background-color: white;
    }

    &.error {
      background-color: rgba(255, 0, 0, 0.301) !important;
    }
  }

  #btn-container {
    padding: 55px 0;
    display: flex;
    justify-content: space-between;
    width: 50%;

    @media screen and (max-width: 991px) {
      align-items: center;
    }

    #submit-btn {
      padding: 12px 10px;
      width: 45% !important;
      font-size: 17px;

      // font-weight: bold;
      @media screen and (max-width: 991px) {
        font-size: 13px;
        width: 60% !important;
        margin-bottom: 10px;
      }

      border-radius: 40px;
      text-transform: uppercase;
      background-color: #036;
      color: white;

      &.active {
        background-color: #003066;
        color: white;

        &:hover {
          cursor: pointer;
          color: white;
          background-color: #003066;
          transition: 0.3s all;
        }
      }

      &:focus {
        outline: none;
      }

      &:hover {
        cursor: pointer;
        color: white;
        background-color: rgb(195 2 4);
        transition: 0.3s all;
      }
    }

    #cancel-btn {
      padding: 12px 10px;
      width: 45% !important;
      font-size: 17px;

      // font-weight: bold;
      @media screen and (max-width: 991px) {
        font-size: 13px;
        width: 60% !important;
      }

      border-radius: 40px;
      text-transform: uppercase;
      background-color: #036;
      color: white;

      &.active {
        background-color: #003066;
        color: white;

        &:hover {
          cursor: pointer;
          color: white;
          background-color: #003066;
          transition: 0.3s all;
        }
      }

      &:focus {
        outline: none;
      }

      &:hover {
        cursor: pointer;
        color: white;
        background-color: rgb(195 2 4);
        transition: 0.3s all;
      }
    }
  }
}</style>