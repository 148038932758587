<template>
  <div id="attr">
    <li id="attr-title">
      {{ title }}
    </li>
    <p id="attr-value">{{ value }}</p>
  </div>
</template>

<script>
export default {
  name: "ApplicationAttr",
  props: ["title", "value", "index"],
  data: function () {
    return {};
  },
};
</script>

<style lang="scss">
#attr {
  width: 26%;
  #attr-title {
    text-transform: uppercase;
    color: #003366;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    list-style-type: none;
  }
  #attr-value {
    text-align: left;
    color: black;
    font-size: 14px;
  }
}
</style>