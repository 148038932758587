<template>
  <div class="header">
    <p class="header-title">
      <img
        src="../assets/menu-icon.png"
        alt="mobile menu"
        ref="menuIcon"
        @click="toggleMobileMenu"
      />
      FLORIDA ATLANTIC UNIVERSITY®
    </p>
    <div class="subtitle"></div>
  </div>
</template>

<script>
export default {
  name: "Header",
  methods: {
    toggleMobileMenu: function () {
      let menu = this.$refs.menuIcon;
      let left = document.getElementById("left");
      let mobileLeft = document.getElementById("mobile-left");
      if (left.className == "left") {
        left.className = "left active";
        menu.className = "menu-active";
        mobileLeft.style.display = "block";
      } else {
        menu.className = "";
        left.className = "left";
        mobileLeft.style.display = "none";
      }
    },
  },
};
</script>

<style lang="scss">
.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Avenir Light;
  border-bottom: 1px solid #00000036;
  img {
    display: none;
    @media screen and(max-width:991px) {
      display: block;
      width: 5%;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .header-title {
    font-size: 11px;
    letter-spacing: 4px;
    color: #036;
    text-transform: uppercase;
    padding: 12px 0px 20px 0px;
    // border-bottom: 1px solid #00000036;
    margin-bottom: unset;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    @media screen and (max-width: 991px) {
      padding: 12px 25px 20px 25px;
      justify-content: flex-end;
      text-align: center;
      img {
        position: fixed;
        left: 25px;
      }
    }
  }
  .subtitle {
    display: flex;
    p {
      text-transform: uppercase;
      font-size: 60px;
    }
  }
}
</style>