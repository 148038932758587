<template>
  <div class="proxy-login">
    <h1>PROXY STUDENT</h1>
    <input
      type="text"
      v-model="input.znum"
      placeholder="Enter Student Z#"
      id="login-inp"
      ref="loginInp"
    />
    <span class="errorMsg">{{ znumError }}</span>

    <button class="active" @click="login" ref="loginBtn">SUBMIT</button>
    <div class="errorMsg">{{ loginError }}</div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Proxy",
  mounted() {
    let loginInp = this.$refs.loginInp;
    let loginBtn = this.$refs.loginBtn;
    loginInp.addEventListener("keyup", function (event) {
      // Number 13 is the "Enter" key on the keyboard
      if (event.keyCode === 13) {
        // Cancel the default action, if needed
        event.preventDefault();
        // Trigger the button element with a click
        loginBtn.click();
      }
    });
  },
  data() {
    return {
      input: {
        znum: "",
      },
      znumError: "",
      loginError: "",
      loading: false,
    };
  },
  //   created: async function () {
  //     //Look for PHP SESSION Cookie
  //     if (document.cookie.match(/PHPSESSID=[^;]+/)) {
  //       //Call login
  //       debugger;
  //       this.isLoading = true;
  //       await axios({
  //         method: "POST",
  //         url: `${API_URL}/getData.php`,
  //         withCredentials: true,
  //         data: {
  //           znum: this.input.znum,
  //           env: process.env.NODE_ENV,
  //         },
  //       }).then((res) => {
  //         debugger;
  //         this.loading = false;
  //         if (res.data?.student) {
  //           this.$emit("login", true, res.data);
  //         } else {
  //           this.loginError = res.data.errorMsg;
  //           this.$emit("login", false);
  //         }
  //         console.log(res);
  //       });
  //       debugger;
  //     } else {
  //       debugger;
  //       this.$emit("login", false);
  //     }
  //     debugger;
  //     this.isLoading = false;
  //   },
  methods: {
    login: function () {
      this.loading = true;
      this.loginError = null;
      //Make sure field inputs are valid
      if (this.validateZNUM(this.input.znum)) {
        // before a request is made start the nprogress
        axios.interceptors.request.use((config) => {
          NProgress.start();
          NProgress.set(0.6);
          return config;
        });

        // before a response is returned stop nprogress
        axios.interceptors.response.use((response) => {
          NProgress.done();
          return response;
        });
        //Get student data from API
        axios({
          method: "POST",
          url: `${API_URL}/getData.php`,
          withCredentials: true,
          data: {
            znum: this.input.znum,
            env: process.env.NODE_ENV,
          },
        })
          .then((res) => {
            this.loading = false;
            if (res.data?.student) {
              this.$emit("login", true, res.data, true);
            } else {
              this.loginError = res.data.errorMsg;
              this.$emit("login", false);
            }
            console.log(res);
          })
          .catch((err) => {
            this.loading = false;
            if (err.response) {
              // client received an error response (5xx, 4xx)
              this.loginError = JSON.stringify(err);
            } else if (err.request) {
              // client never received a response, or request never left
              this.loginError = JSON.stringify(err);
            } else {
              console.log(err);
            }
          });
      } else {
        this.loading = false;
      }
    },
    validateZNUM: function (znum) {
      let isValid = true;
      if (!znum) {
        this.znumError = "Please type in a valid Z number.";
        isValid = false;
      } else if (!/^[z,Z][0-9]{7}\w/.test(znum)) {
        this.znumError = "You have entered an invalid Z number!";
        isValid = false;
      }
      if (isValid) this.znumError = "";
      return isValid;
    },
  },
};
</script>

<style lang="scss">
.proxy-login {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 80%;
  input {
    width: 40%;
    padding: 12px 5px;
    font-size: 18px;
    @media screen and(max-width:991px) {
      font-size: 13px;
    }
    background-color: rgba(105, 105, 105, 0.089);
    &:focus {
      background-color: white;
      outline: none;
    }
    &:active {
      background-color: white;
    }
    &.error {
      background-color: rgba(255, 0, 0, 0.301) !important;
    }
  }
  .errorMsg {
    color: red;
    font-size: 13px;
    text-align: left;
  }
  button {
    padding: 10px 20px;
    box-shadow: 0px 0px 8px #565555;
    margin-bottom: 20px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.212);
    font-size: 17px;
    font-weight: 600;
    border-radius: 40px;
    width: 20%;
    margin-top: 60px;
    &.active {
      background-color: #003066;
      color: white;
      &:hover {
        cursor: pointer;
        color: white;
        background-color: #003066;
        transition: 0.3s all;
      }
    }
    &:focus {
      outline: none;
    }
    &:hover {
      cursor: pointer;
      color: white;
      background-color: rgb(195 2 4);
      transition: 0.3s all;
    }
  }
}
</style>