<template>
  <span>
    <slot v-if="verifEmailSent == false"></slot> <!-- Whatever is between the component's tags (if any) will be inserted in the <slot></slot> https://vuejs.org/v2/guide/components-slots.html#Slot-Content -->
    <div>
      <!-- Before Email is sent -->
      <div v-if="this.verifEmailSent == false">
        <div v-if="this.verifEmailSending" id="TSTS_sendingMsg">
          Sending verification email...
        </div>
        <div v-if="this.errorMsg" id="TSTS_errorMsg">
          {{ errorMsg }}
        </div>
        <button id="sendEmailBtn" @click="sendVerificationEmail">Update Scores</button>
      </div>
      <!-- After Email has been sent -->
      <div v-if="this.verifEmailSent == true">
        <EmailVerificationCodePopup
          :email="this.studentData?.original?.email?.trim()"
          :studentData="this.studentData"
        />
      </div>
    </div>
  </span>
</template>

<script>
import EmailVerificationCodePopup from "./emailVerificationCodePopup.vue";
import axios from "axios";
export default {
  name: "TestScoreUpdateButton",
  components: { EmailVerificationCodePopup },
  props: ["confNum", "znum", "studentData"],
  data: function () {
    return {
      verifEmailSending: false,
      verifEmailSent: false,
      errorMsg: null,
    };
  },
  created: function () {},
  methods: {
    sendVerificationEmail: function () {
      const email = this.studentData?.original?.email?.trim();
      if (email) {
        // Send verification code by email
        this.verifEmailSending = true;
        axios({
          method: "POST",
          url: `${API_URL}/emailToVerifyUser.php`,
          withCredentials: true,
          data: {
            email: email,
            conf: this.studentData.CONF_NO,
            env: process.env.NODE_ENV,
          },
        })
          .then((res) => {
            console.log(res);
            this.verifEmailSending = false;
            if (res.data?.Message == "Email sent!") {
              this.verifEmailSent = true;
            } else {
              this.errorMsg = res.data?.Message;
              this.verifEmailSent = false;
            }
          })
          .catch((err) => {
            NProgress.done(); //Remove the loading bar
            this.verifEmailSending = false;
            this.errorMsg = err.message;
            //   "We have encountered an error in our system. We are working on fixing the issue. Please try again later.";
            console.log(err);
          });
      } else {
        this.errorMsg = "Error: Could not find email address.";
      }
    },
  },
};
</script>

<style lang="scss">
  #sendEmailBtn {
    margin: 0 auto;
    width: 40%;
    color: white;
    background-color: #036;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    padding: 10px 20px;
    border: transparent;
    box-shadow: 0px 0px 8px #56555534;
    border: 1px solid rgba(0, 0, 0, 0.212);
    border-radius: 40px;
    &:hover {
      cursor: pointer;
      background-color: rgb(0, 68, 136);
    }
    &:active {
      cursor: pointer;
      background-color: rgb(0, 41, 82);
    }
  }
  #TSTS_errorMsg {
    color: red;
    font-size: 20px;
    margin-bottom: 30px;
  }
  #TSTS_sendingMsg {
    font-size: 20px;
    margin-bottom: 30px;
  }
</style>