<template>
  <div class="incomplete-tasks">
    <h1>Incomplete</h1>
    <!-- <p>Some verbiage about incomplete tasks...</p> -->
    <p v-html="incMsg"></p>
    <div class="tasks-container">
      <Task
        v-for="(task, i) in tasks"
        :key="i"
        :task="task"
        :level="'incomplete'"
        :confNum="this.confNum"
        :znum="this.znum"
        :studentData="this.studentData"
      />
    </div>
  </div>
</template>

<script>
import Task from "../subcomponents/task.vue";

export default {
  props: ["tasks", "confNum", "incMsg", "znum", "studentData"],
  components: { Task },
  created() {},
  data() {
    return {};
  },
  methods: {
    getTask: function (task) {
      let taskObj = this.tasks.ChecklistItemsUIData.filter((checklistTask) => {
        return task == checklistTask.checkl_code;
      });
      return taskObj[0];
    },
  },
};
</script>

<style lang="scss">
.tasks-container {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-evenly;
  align-items: center;
}
.incomplete-tasks {
  padding: 10px 0 40px 0;
  h1 {
    text-align: left;
    color: #cc0000;
  }
  p {
    text-align: left;
    font-size: 17px;
  }
}
</style>